th {
  text-align: center;
}

.cart__img-box {
  width: 80px;
}

.cart__img-box img {
  width: 100%;
}

.cart__item-del i {
  cursor: pointer;
}

.cart__page-btn {
  display: flex;
  flex-wrap: nowrap;
  margin: 2rem 0;
}

.cart__page-btn button a:hover {
  color: #fff;
}

.cart__subtotal {
  color: #df2020;
  font-size: 1.5rem;
}

.addTOCart__btn {
  border: none;
  padding: 7px 25px;
  background: #df2020;
  color: #fff;
  border-radius: 5px;
  font-size: 0.9rem;
}

@media only screen and (max-width: 768px) {
  th,
  td {
    font-size: 0.8rem;
  }
}
